<template>
  <div class="advantage" id="advantage">
    <div class="advantage-title">
      <span class="advantage-title-text">
        <span style="float: left;color: #9DA4AF">为什么</span>
        选择我们
      </span>
    </div>
    <div style="margin-top: 40px;">
      <span class="advantage-slogan-text">不仅是简单的需求输出，更旨在与您一起向上成长，协同完成更好的作品，高质量提供服务。<br/></span>
      <span class="advantage-slogan-text">用智慧创造价值，助力企业腾飞发展</span>
    </div>
    <div class="advantage-content">
      <div class="advantage-content-top">
        <div class="advantage-content-top-left"
             :style="{background:'url(' + tdpz + ')', backgroundSize:'100% 100%', height: '283px', width: '535px', float: 'left'}">
          <span class="advantage-content-left-title">全流程解决方案</span>
          <span class="advantage-content-left-text">从业务场景到需求梳理，再到产研和上线运营，全程服务并为您提供专业的产品与技术建议</span>
        </div>
        <div class="advantage-content-top-right"
             :style="{background:'url(' + wdsc + ')', backgroundSize:'100% 100%', height: '283px', width: '535px', float: 'left'}">
          <span class="advantage-content-right-title">文档输出</span>
          <span class="advantage-content-right-text">方案、场景、原型、设计稿、... 文档完备，规范化流程输出，高标准质量要求，确保良好的合作体验</span>
        </div>
      </div>
      <div class="advantage-content-bottom">
        <div class="advantage-content-bottom-left"
             :style="{background:'url(' + sxpz + ')', backgroundSize:'100% 100%', height: '283px', width: '358px', float: 'left'}">
          <span class="advantage-content-bottom-left-title">思想碰撞</span>
          <span class="advantage-content-bottom-left-text">协同分析，提供最优质的意见方案</span>
        </div>
        <div class="advantage-content-bottom-middle"
             :style="{background:'url(' + tdcy + ')', backgroundSize:'100% 100%', height: '283px', width: '358px', float: 'left'}">
          <span class="advantage-content-bottom-left-title">长期及时支持</span>
          <span class="advantage-content-bottom-left-text">助力企业的长期发展，长期的支持与服务</span>
        </div>
        <div class="advantage-content-bottom-right"
             :style="{background:'url(' + qt + ')', backgroundSize:'100% 100%', height: '283px', width: '358px', float: 'left'}">
          <span class="advantage-content-bottom-left-title">安全保障</span>
          <span class="advantage-content-bottom-left-text">业务、数据、代码全方面的安全保障</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Advantage",
  data() {
    return {
      tdpz: require('@/assets/advantage/tdpz.png'),
      wdsc: require('@/assets/advantage/wdsc.png'),
      sxpz: require('@/assets/advantage/sxpz.png'),
      tdcy: require('@/assets/advantage/tdcy.png'),
      qt: require('@/assets/advantage/qt.png')
    }
  }
}
</script>

<style scoped>
.advantage {
  height: 800px;
  padding-top: 50px;
}

.advantage-title, .advantage-content {
  display: inline-table;
  justify-content: center;
  align-items: flex-end;
}

.advantage-content {
  margin-top: 50px;

}

.advantage-title-text {
  width: 270px;
  height: 37px;
  font-size: 38px;
  font-weight: bold;
  color: #243042;
}

.advantage-slogan-text {
  font-size: 20px;
  font-weight: 400;
  color: #5A677A;
  line-height: 40px;
}

.advantage-content-top {
  width: 100%;
}

.advantage-content-bottom {
  width: 100%;
}

.advantage-content-left-title {
  position: absolute;
  font-size: 24px;
  font-weight: 400;
  color: #1890FF;
  top: 60px;
  left: 60px;
}
.advantage-content-left-text {
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: #598DBD;
  top: 120px;
  left: 60px;
  width: 250px;
  text-align: left;
  line-height: 35px;
}

.advantage-content-top-left, .advantage-content-top-right, .advantage-content-bottom-left, .advantage-content-bottom-middle, .advantage-content-bottom-right {
  position: relative;
}

.advantage-content-right-title {
  position: absolute;
  font-size: 24px;
  font-weight: 400;
  color: #1890FF;
  top: 60px;
  left: 60px;
}

.advantage-content-right-text {
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: #598DBD;
  top: 120px;
  left: 60px;
  width: 250px;
  text-align: left;
  line-height: 35px;
}

.advantage-content-bottom-left-title {
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  color: #4C5461;
  bottom: 65px;
  left: 40px;
}

.advantage-content-bottom-left-text {
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: #9DA4AF;
  bottom: 35px;
  left: 40px;
}
</style>
