<template>
  <div class="idea" id="idea" :style="{background:'url(' + bg + ')', backgroundSize:'100% 100%'}">
    <top/>
    <div>
      <img src="@/assets/idea/slogan.png" class="slogan"/>
      <img src="@/assets/idea/jx15.png" class="jx15"/>
      <img src="@/assets/idea/jx13.png" class="jx13"/>
    </div>
    <div style="height: 400px;">
      <img src="@/assets/idea/jx14.png" class="jx14"/>
      <img src="@/assets/idea/make-value.png" class="make-value"/>
      <img src="@/assets/idea/jx12.png" class="jx12"/>
    </div>
    <div class="jt-div">
      <img src="@/assets/idea/jt.png" class="jt" @click="goNext"/>
    </div>
  </div>
</template>

<script>
import Top from "@/views/Top";

export default {
  name: "Idea",
  components: {
    Top
  },
  data() {
    return {
      bg: require('@/assets/idea/back-ground.png'),
    }
  },
  methods: {
    goNext() {
      this.$router.push("#scope")
    }
  }
}
</script>

<style scoped>
.idea {
  width: 100%;
  height: 860px;
}

.slogan {
  width: 805px;
  height: 85px;
  margin-top: 220px;
  z-index: 2;
  position: relative;
  left: 230px;
}

.make-value {
  width: 397px;
  height: 145px;
  margin-top: 80px;
}

.jx15 {
  width: 305px;
  height: 291px;
  z-index: 1;
  position: relative;
  right: 60px;
  top: 50px;
}

.jx14 {
  width: 174px;
  height: 174px;
  z-index: 1;
  position: relative;
  right: 350px;
  top: 50px;
}

.jx13 {
  width: 174px;
  height: 174px;
  z-index: 1;
  position: relative;
  right: 20px;
  top: 150px;
}

.jx12 {
  width: 174px;
  height: 174px;
  z-index: 1;
  position: relative;
  top: 200px;
  left: 150px;
}

.jt-div {
  width: 100%;
  cursor: pointer;
}

.jt {
  width: 30px;
}
</style>
