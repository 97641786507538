<template>
  <div class="customer" id="customer">
    <div class="customer-title">
      <span class="customer-title-text">用户好评</span>
    </div>
    <div style="margin-top: 40px;">
      <span class="customer-content-text">用户的建议让我们有了今天的成就</span>
    </div>
    <div class="customer-content">
      <img src="@/assets/customer/customer.png" class="customer-img"  alt=""/>
      <div class="customer-ul">
        <div class="customer-li">
          <img src="@/assets/customer/1.png" class="customer-li-bg one"  alt=""/>
          <span class="customer-text">自合作以来，四典科技一直对我司秉持 着专业、贴心服务、协助进步的友好理 念，特别感谢四典科技的支持，让我司 的业务能够更快更好的进行下去，希望 四典能够继续发展大展宏图。</span>
          <img src="@/assets/customer/avatar1.png" class="customer-text-img" alt="">
          <span class="customer-name">陈继焕</span>
          <span class="customer-post">厦门顺象贸易有限公司</span>
        </div>
        <div class="customer-li">
          <img src="@/assets/customer/2.png" class="customer-li-bg two"  alt=""/>
          <span class="customer-text">非常专业，能够充分理解我方需求，并且站在我们企业的角度，为我们提供了很多有建设性的服务，期待与四典科技能够保持更加紧密的合作。</span>
          <img src="@/assets/customer/avatar2.png" class="customer-text-img" style="bottom: 135px;" alt="">
          <span class="customer-name" style="bottom: 162px;">陈君宜</span>
          <span class="customer-post" style="bottom: 132px;">厦门鼎塔汽车报废有限公司</span>
        </div>
        <div class="customer-li">
          <img src="@/assets/customer/3.png" class="customer-li-bg three"  alt=""/>
          <span class="customer-text">四典团队为我司提供平台搭建服务的过程中，体现出了优秀的实力和专业的服务精神，很满意，很放心，希望以后有机会能够多多合作。</span>
          <img src="@/assets/customer/avatar3.png" class="customer-text-img" style="bottom: 100px;" alt="">
          <span class="customer-name" style="bottom: 127px;">洪荣迪</span>
          <span class="customer-post" style="bottom: 97px;">江苏宏佑地产集团有限公司</span>
        </div>
      </div>
      <div class="customer-ul">
        <div class="customer-li">
          <img src="@/assets/customer/4.png" class="customer-li-bg four"  alt=""/>
          <span class="customer-text">四典科技用他们热情、专业、高效的服务，为我们公司带来了远超预期的优质的体验，非常感谢四典团队的服务。</span>
          <img src="@/assets/customer/avatar4.png" class="customer-text-img" alt="">
          <span class="customer-name">柯德深</span>
          <span class="customer-post">晋江霆瀚伞业有限公司</span>
        </div>
        <div class="customer-li">
          <img src="@/assets/customer/5.png" class="customer-li-bg five"  alt=""/>
          <span class="customer-text" style="top: 0;">四典团队以专业的知识、丰富的经验及真诚的服务，梳理业务、提供方案，协助优化管理流程，提升企业办公效率，对我司来讲，贵司是一家值得信赖的合作伙伴。</span>
          <img src="@/assets/customer/avatar5.png" class="customer-text-img" alt="">
          <span class="customer-name">蔡天恩</span>
          <span class="customer-post">晋江维特鲁有限责任公司</span>
        </div>
        <div class="customer-li">
          <img src="@/assets/customer/6.png" class="customer-li-bg six"  alt=""/>
          <span class="customer-text" style="top: 30px;">不管是专业水平上还是项目的服务程度上，四典科技都给予了很大的信心，最终的结果也很满意，是值得长期合作并保持友好沟通的好公司。</span>
          <img src="@/assets/customer/avatar6.png" class="customer-text-img" alt="">
          <span class="customer-name">蔡金洋</span>
          <span class="customer-post">泉州洋明科技有限公司</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerPraise"
}
</script>

<style scoped>
.customer {
  height: 1200px;
}

.customer-title {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: flex-end;
}

.customer-title-text {
  height: 37px;
  font-size: 38px;
  font-weight: bold;
  color: #243042;
}

.customer-content-text {
  font-size: 20px;
  font-weight: 400;
  color: #5A677A;
}

.customer-content {
  margin-top: 40px;
}

.customer-img {
  width: 60%;
  margin-bottom: 20px;
}

.customer-ul {
  width: 100%;
  display: flex;
  justify-content: center;
}

.customer-li {
  position: relative;
}

.customer-li-bg {
  width: 401px;
}

.one {
  height: 405px;
}

.two {
  height: 325px;
}

.three {
  height: 365px;
}

.four {
  height: 325px;
}

.five {
  height: 405px;
  margin-top: -80px;
}

.six {
  height: 365px;
  margin-top: -40px;
}

.customer-text {
  width: 275px;
  position: absolute;
  text-align: left;
  left: 65px;
  top: 70px;
  height: auto;
  line-height: 35px;
}

.customer-text-img {
  position: absolute;
  left: 50px;
  bottom: 70px;
  width: 50px;
}

.customer-name {
  position: absolute;
  left: 115px;
  bottom: 97px;
  font-size: 18px;
  font-weight: 400;
  color: #243042;
}

.customer-post {
  position: absolute;
  left: 115px;
  bottom: 67px;
  font-size: 14px;
  font-weight: 400;
  color: #9A9A9A;
}
</style>
