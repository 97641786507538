<template>
  <div class="home">
    <idea/>
    <scope/>
    <case-study/>
    <advantage/>
    <pursue/>
    <customer-praise />
    <contact-us/>
    <bottom />
  </div>
</template>

<script>
import Idea from "@/components/Idea";
import Scope from "@/components/Scope";
import CaseStudy from "@/components/CaseStudy";
import Advantage from "@/components/Advantage";
import Pursue from "@/components/Pursue";
import ContactUs from "@/components/ContactUs";
import CustomerPraise from "@/components/CustomerPraise";
import Bottom from "@/components/Bottom";

export default {
  name: 'Home',
  components: {
    CustomerPraise,
    ContactUs,
    Pursue,
    Advantage,
    CaseStudy,
    Scope,
    Idea,
    Bottom
  }
}
</script>
