<template>
  <div class="case-study" id="case-study"
       :style="{background:'url(' + bg + ')', backgroundSize:'100% 100%'}">
    <div class="case-title">
      <span class="case-title-text">案例展示</span>
    </div>
    <div style="margin-top: 40px;">
      <span class="case-content-text">竭尽全力为您创造更高级的价值</span>
    </div>
    <div class="case-swiper">
      <swiper-container/>
    </div>
  </div>
</template>

<script>
import SwiperContainer from "@/components/SwiperContainer";

export default {
  name: "CaseStudy",
  components: {SwiperContainer},
  data() {
    return {
      bg: require('@/assets/idea/back-ground.png')
    }
  }
}
</script>

<style scoped>
.case-study {
  width: 100%;
  height: 900px;
}

.case-title, .case-content {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: flex-end;
}

.case-title-text {
  width: 162px;
  height: 37px;
  font-size: 38px;
  font-weight: bold;
  color: #243042;
}

.case-content-text {
  font-size: 20px;
  font-weight: 400;
  color: #5A677A;
}

.case-swiper {
  margin-top: 100px;
}
</style>
