<template>
  <div class="pursue" id="pursue">
    <div class="pursue-title">
      <span class="pursue-title-text">追求极致的用户体验</span>
    </div>
    <div style="margin-top: 40px;">
      <span class="pursue-content-text">我们重视服务、设计、交付以及每一个细节，我们相信追求极致才是对用户的尊重。</span>
    </div>
    <div class="pursue-content">
      <div class="pursue-content-ul">
        <div class="pursue-point">
          <img src="@/assets/pursue/design.png" class="pursue-point-image" />
          <div class="point-title">注重设计</div>
          <div class="point-text">内外兼修，设计也是一种力量</div>
        </div>
        <div class="pursue-point">
          <img src="@/assets/pursue/say.png" class="pursue-point-image" />
          <div class="point-title">讲究细节</div>
          <div class="point-text">最全的场景，最微小的产品体验</div>
        </div>
        <div class="pursue-point">
          <img src="@/assets/pursue/afraid.png" class="pursue-point-image" />
          <div class="point-title">保持敬畏</div>
          <div class="point-text">对行业的敬畏的初心不改</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pursue"
}
</script>

<style scoped>
.pursue {
  height: 700px;
  margin-top: 150px;
}

.pursue-title {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: flex-end;
}

.pursue-title-text {
  height: 37px;
  font-size: 38px;
  font-weight: bold;
  color: #243042;
}

.pursue-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.pursue-content-text {
  font-size: 20px;
  font-weight: 400;
  color: #5A677A;
}

.pursue-content-ul {
  width: 80%;
}

.pursue-point {
  width: 33%;
  float: left;
}

.pursue-point-image {
  width: 162px;
  height: 162px;
}

.point-title {
  font-size: 24px;
  font-weight: bold;
  color: #4E5D75;
}

.point-text {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  color: #61646C;
}

</style>
