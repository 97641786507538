<template>
  <div class="scope" id="scope">
    <div class="scope-title">
      <span class="scope-title-text">服务范围</span>
    </div>
    <div class="scope-content">
      <div :style="{background:'url(' + bg + ')', backgroundSize:'100% 100%', height: '355px', width: '1181px'}">
        <div class="scope-left">
          <div class="scope-profession">专业人做专业事</div>
          <div class="scope-profession-content" style="width: 240px;">为独特的你赋能，一起搭建新场景 服务好每一位顾客，共鸣共生共创</div>
          <div class="scope-profession-content">想你所想，做你所需，尽我所能，让1+1>2</div>
        </div>
        <div class="scope-right">
          <div class="scope-fw-div">
            <img src="@/assets/scope/fw1.png" class="scope-fw"/>
            <div class="scope-fw-text">新时代设计</div>
            <div class="scope-fw-detail">图片、视频、PPT、平面</div>
          </div>
          <div class="scope-fw-div">
            <img src="@/assets/scope/fw2.png" class="scope-fw"/>
            <div class="scope-fw-text">产品研发</div>
            <div class="scope-fw-detail">App、H5、抖音/微信小程序</div>
          </div>
          <div class="scope-fw-div">
            <img src="@/assets/scope/fw3.png" class="scope-fw"/>
            <div class="scope-fw-text">互联网运营</div>
            <div class="scope-fw-detail">公众号/产品运营、大数据处理</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Scope",
  data() {
    return {
      bg: require('@/assets/scope/bg.png')
    }
  }
}
</script>

<style scoped>
.scope {
  height: 700px;
}

.scope-title, .scope-content {
  display: flex;
  height: 170px;
  justify-content: center;
  align-items: flex-end;
}

.scope-left {
  float: left;
  width: 400px;
}

.scope-right {
  margin-top: 40px;
  float: left;
}

.scope-fw-div {
  margin-left: 35px;
  float: left;
}

.scope-fw {
  width: 200px;
}

.scope-fw-text {
  text-align: center;
  width: 200px;
  font-size: 20px;
  font-weight: normal;
  color: #4E5D75;
}

.scope-fw-detail {
  margin-top: 15px;
  text-align: center;
  width: 200px;
  font-size: 14px;
  font-weight: 400;
  color: #A6A6A6;
}

.scope-content {
  margin-top: 280px;
}

.scope-title-text {
  width: 162px;
  height: 37px;
  font-size: 38px;
  font-weight: bold;
  color: #243042;
}

.scope-profession {
  font-size: 24px;
  font-weight: 400;
  color: #243042;
  margin-left: 73px;
  margin-top: 113px;
  margin-bottom: 20px;
  display: flex;
}

.scope-profession-content {
  margin-left: 73px;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #5A677A;
  text-align: left;
  line-height: 40px;
}
</style>
