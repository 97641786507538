<template>
  <div class="contact-us" id="contact-us" :style="{background:'url(' + bg + ')', backgroundSize:'100% 100%', height: '420px', width: '100%'}">
    <div class="contact-content">
      <div class="contact-left">
        <div class="contact-title">
          <span class="contact-title-text">准备好开始了吗 ？</span>
        </div>
        <div style="margin-top: 40px;">
          <span class="contact-content-text">联系我们</span>
        </div>
        <div style="margin-top: 20px;">
          <img src="@/assets/contact/btn.png" class="contact-btn"  alt=""/>
        </div>
      </div>
      <div class="contact-right">
        <img src="@/assets/contact/qrcode.png" class="contact-qrcode"  alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactUs",
  data() {
    return {
      bg: require('@/assets/contact/bg.png')
    }
  }
}
</script>

<style scoped>
.contact-content {
  width: 60%;
  height: 190px;
}

.contact-us {
  height: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-title {
  display: flex;
  height: 55px;
  justify-content: center;
  align-items: center;
}

.contact-title-text {
  height: 37px;
  font-size: 38px;
  font-weight: bold;
  color: #243042;
}

.contact-content-text {
  font-size: 20px;
  font-weight: 400;
  color: #5A677A;
}

.contact-btn {
  width: 150px;
  height: 70px;
  cursor: pointer;
}

.contact-qrcode {
  width: 426px;
  height: 277px;
}

.contact-left {
  float: left;
  width: 50%;
}

.contact-right {
  margin-top: -25px;
  float: right;
  width: 50%;
}
</style>
