<template>
  <div id="certify">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="@/assets/swiper/晋江.png"/>
        </div>
        <div class="swiper-slide">
          <img src="@/assets/swiper/霆瀚.png"/>
        </div>
        <div class="swiper-slide">
          <img src="@/assets/swiper/维特鲁.png"/>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import '@/assets/swiper/swiper.min.css'
import '@/assets/swiper/certify.css'
import Swiper from 'swiper'
export default {
  name: "Swiper",
  mounted() {
    new Swiper('#certify .swiper-container', {
      watchSlidesProgress: true,
      slidesPerView: 'auto',
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 5000,//1秒切换一次
      },
      loopedSlides: 5,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        //clickable :true,
      },
      on: {
        progress: function () {
          for (let i = 0; i < this.slides.length; i++) {
            const slide = this.slides.eq(i);
            const slideProgress = this.slides[i].progress;
            let modify = 1;
            if (Math.abs(slideProgress) > 1) {
              modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
            }
            const translate = slideProgress * modify * 260 + 'px';
            const scale = 1 - Math.abs(slideProgress) / 5;
            const zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
            slide.transform('translateX(' + translate + ') scale(' + scale + ')');
            slide.css('zIndex', zIndex);
            slide.css('opacity', zIndex === 999 ? 1 : 0.7);
            if (Math.abs(slideProgress) > 3) {
              slide.css('opacity', 0);
            }
          }
        },
        setTransition: function (transition) {
          for (let i = 0; i < this.slides.length; i++) {
            const slide = this.slides.eq(i)
            slide.transition(transition);
          }
        }
      }
    })
  }
}
</script>

<style scoped>
</style>
