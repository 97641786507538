<template>
  <div class="footer">
    <img src="@/assets/logo.png" class="footer-logo" />
    <span class="footer-text company">版权所有 2021 厦门四典科技有限公司</span>
    <span class="footer-text number">闽ICP备2021013053号</span>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #172B4D;
}

.footer-logo {
  width: 24px;
  height: 24px;
}

.footer-text {
  font-size: 14px;
  font-weight: 400;
  color: #B7B8BA;
}

.company {
  margin-left: 13px;
}

.number {
  margin-left: 39px;
}
</style>
