<template>
  <div class="top">
    <div class="top-img">
      <img src="@/assets/logo.png" class="logo" alt=""/>
      <img src="@/assets/logo-text.png" class="logo-text" alt=""/>
    </div>
    <div id="nav">
      <router-link to="#idea" class="idea">理念</router-link>
      <router-link to="#scope">范围</router-link>
      <router-link to="#case-study">案例</router-link>
      <router-link to="#advantage">优势</router-link>
      <router-link to="#pursue">追求</router-link>
      <router-link to="#contact-us">联系我们</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "Top"
}
</script>

<style scoped>
.top-img {
  height: 83px;
  float: left;
  display: flex;
  align-items: center;
  margin-left: 20%;
}
.logo {
  float: left;
  height: 42px;
  margin-left: 20%;
}

.logo-text {
  float: left;
  height: 20px;
  margin-left: 15px;
}

#nav {
  padding: 30px;
}
#nav a {
  margin-left: 52px;
  font-weight: bold;
  color: #2c3e50;
  text-decoration: unset;
}
.idea {
  color: #1C50FF !important;
}
</style>
